import { TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SoftBox from "myComponents/SoftBox";

const MPINInput = ({ onMPINChange, title = "", ifMatching, reset }) => {
  const [mpin, setMPIN] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, e) => {
    e.preventDefault();
    const { value } = e.target;
    const lastChar = value.charAt(value.length - 1);

    // Only proceed if input is a number
    if (/^[0-9]$/.test(lastChar)) {
      setMPIN((prevMPIN) => {
        const newMPIN = [...prevMPIN];
        newMPIN[index] = lastChar;
        return newMPIN;
      });

      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index >= 0) {
      setMPIN((prevMPIN) => {
        const newMPIN = [...prevMPIN];
        newMPIN[index] = "";
        return newMPIN;
      });

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    // Prevent non-numeric input
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
      return;
    }

    // Handle backspace
    if (e.key === "Backspace") {
      handleBackspace(index, e);
    }
  };

  useEffect(() => {
    const enteredMPIN = mpin.join("");
    onMPINChange(enteredMPIN);
  }, [mpin, onMPINChange]);

  // Separate cleanup effect that only runs on unmount
  useEffect(
    () => () => {
      setMPIN(["", "", "", ""]);
      inputRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
          ref.current.removeEventListener("keydown", handleBackspace);
        }
      });
    },
    []
  );

  // Add effect to handle reset
  useEffect(() => {
    if (reset) {
      setMPIN(["", "", "", ""]);
      inputRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });
      inputRefs[0].current?.focus();
    }
  }, [reset]);

  return (
    <SoftBox pt={1}>
      <Typography pb={1} pt={1} variant="caption" component="p">
        <b>{title}</b>
      </Typography>
      {mpin.map((digit, index) => (
        <TextField
          key={index}
          inputRef={inputRefs[index]}
          variant="outlined"
          type="password"
          inputProps={{
            maxLength: 1,
            inputMode: "numeric",
          }}
          value={digit}
          onChange={(e) => handleInputChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          autoFocus={index === 0}
          style={{ width: "3rem", marginRight: "1rem" }}
        />
      ))}
      &nbsp;
      {ifMatching === "matched" && (
        <CheckCircleIcon
          sx={{
            verticalAlign: "-webkit-baseline-middle",
            color: (theme) => theme.palette.primary.main,
          }}
          fontSize="medium"
        />
      )}
      {ifMatching === "notMatched" && (
        <CancelIcon
          sx={{
            verticalAlign: "-webkit-baseline-middle",
            color: (theme) => theme.palette.error.main,
          }}
          fontSize="medium"
        />
      )}
    </SoftBox>
  );
};

export default MPINInput;
