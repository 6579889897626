// React and Redux imports
import { useDispatch } from "react-redux";

// Formik and validation imports
import { useFormik } from "formik";
import * as yup from "yup";

// Material-UI components
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Custom components
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import SoftAutoCompleteV2 from "myComponents/SoftAutoComplete/v2";

// Components
import DialogV1 from "components/CustomDialog/v1";

// Actions
import { addAssetGroupStart } from "redux/inventory/slice";

const AssetTypes = [
  { label: "Fixed Assets", value: "fixed_assets" },
  { label: "Current Assets", value: "current_assets" },
  { label: "Intangible Assets", value: "intangible_assets" },
  { label: "Tangible Assets", value: "tangible_assets" },
  { label: "Financial Assets", value: "financial_assets" },
  { label: "Non-Current Assets", value: "non_current_assets" },
  { label: "Consumable Assets", value: "consumable_assets" },
  { label: "Other Assets", value: "other_assets" },
];

const DepreciationMethods = [
  {
    label: "Straight Line Depreciation Method",
    value: "straight_line_depreciation_method",
  },
  { label: "Diminishing Balance Method", value: "diminishing_balance_method" },
  { label: "Sum Of Years Digits Method", value: "sum_of_years_digits_method" },
  {
    label: "Double Declining Balance Method",
    value: "double_declining_balance_method",
  },
  { label: "Sinking Fund Method", value: "sinking_fund_method" },
  { label: "Annuity Method", value: "annuity_method" },
  { label: "Insurance Policy Method", value: "insurance_policy_method" },
  {
    label: "Discounted Cash Flow Method",
    value: "discounted_cash_flow_method",
  },
  { label: "Output Method", value: "output_method" },
  { label: "Working Hours Method", value: "working_hours_method" },
  { label: "Mileage Method", value: "mileage_method" },
  { label: "Depletion Method", value: "depletion_method" },
  { label: "Revaluation Method", value: "revaluation_method" },
  { label: "Group Or Composite Method", value: "group_or_composite_method" },
];

const ManageAssetGroupsDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      assetType: "",
      assetGroupName: "",
      assetGroupCode: "",
      depreciationMethod: "",
      depreciationRate: 0,
    },
    validationSchema: yup.object({
      assetType: yup.object({
        label: yup.string().required("Asset Type is required"),
        value: yup.string().required("Asset Type is required"),
      }),
      assetGroupName: yup.string().required("Asset Group Name is required"),
      assetGroupCode: yup.string().required("Asset Group Code is required"),
      depreciationMethod: yup.object({
        label: yup.string(),
        value: yup.string(),
      }),
      depreciationRate: yup
        .number()
        .min(0, "Depreciation Rate must be positive"),
    }),
    onSubmit: (values, { resetForm }) => {
      const item = {
        ...values,
      };
      item.assetType = item.assetType.value;
      item.depreciationMethod = item.depreciationMethod.value;
      dispatch(addAssetGroupStart(item));
      resetForm();
      handleClose();
    },
  });

  return (
    <DialogV1 open={open} onClose={handleClose} title="Asset Group">
      <SoftBox pl={3} pr={2} pb={0} pt={0} mt={2} mb={2}>
        <SoftBox>
          <SoftBox borderRadius="md">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      color="error"
                    >
                      Asset Type*
                    </SoftTypography>
                  </SoftBox>
                  <SoftAutoCompleteV2
                    name="assetType"
                    options={AssetTypes}
                    value={formik.values.assetType}
                    error={
                      formik.touched.assetType &&
                      Boolean(formik.errors.assetType)
                    }
                    onChange={(e, value) =>
                      formik.setFieldValue("assetType", value)
                    }
                    optionLabelPropName="label"
                    placeholder="Select Asset Type"
                    optionValuePropName="value"
                    onBlur={formik.handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      color="error"
                    >
                      Asset Group Name*
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftInput
                      name="assetGroupName"
                      type="text"
                      value={formik.values.assetGroupName}
                      error={
                        formik.touched.assetGroupName &&
                        Boolean(formik.errors.assetGroupName)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      color="error"
                    >
                      Asset Group Code*
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftInput
                      name="assetGroupCode"
                      type="text"
                      value={formik.values.assetGroupCode}
                      error={
                        formik.touched.assetGroupCode &&
                        Boolean(formik.errors.assetGroupCode)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      color="dark"
                    >
                      Depreciation Method
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftAutoCompleteV2
                      name="depreciationMethod"
                      options={DepreciationMethods}
                      value={formik.values.depreciationMethod}
                      error={
                        formik.touched.depreciationMethod &&
                        Boolean(formik.errors.depreciationMethod)
                      }
                      onChange={(e, value) =>
                        formik.setFieldValue("depreciationMethod", value)
                      }
                      optionLabelPropName="label"
                      placeholder="Select Depreciation Method"
                      optionValuePropName="value"
                      onBlur={formik.handleBlur}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      color="dark"
                    >
                      Depreciation Rate
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftInput
                      name="depreciationRate"
                      type="number"
                      value={formik.values.depreciationRate}
                      error={
                        formik.touched.depreciationRate &&
                        Boolean(formik.errors.depreciationRate)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mt={2}
                width="100%"
                gap={1}
              >
                <SoftButton type="submit" color="info" size="small">
                  Save
                </SoftButton>
                <SoftButton
                  onClick={handleClose}
                  color="secondary"
                  size="small"
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Divider sx={{ margin: 0 }} />
    </DialogV1>
  );
};

export default ManageAssetGroupsDialog;
