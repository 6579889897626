// React and Redux imports
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Formik and validation imports
import { useFormik } from "formik";
import * as yup from "yup";

// Material-UI components
import Divider from "@mui/material/Divider";

// Custom components
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";

// Redux selectors and actions
import { selectBrands } from "redux/inventory/selectors";
import { addBrandStart, updateBrandStart } from "redux/inventory/slice";

// Components
import DialogV1 from "components/CustomDialog/v1";

const ManageBrandsDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const brands = useSelector(selectBrands);
  const [isAdding, setIsAdding] = useState(false);
  const [editBrand, setEditBrand] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: editBrand ? editBrand.name : "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (editBrand) {
        dispatch(updateBrandStart({ id: editBrand._id, ...values }));
      } else {
        dispatch(addBrandStart(values));
      }
      resetForm();
      setIsAdding(false);
      setEditBrand(null);
    },
  });

  const handleEdit = (brand) => {
    setEditBrand(brand);
    setIsAdding(true);
  };

  return (
    <DialogV1 open={open} onClose={handleClose} title="Manage Brands">
      <SoftBox pl={3} pr={2} pb={0} pt={0} mt={2} mb={2}>
        <SoftBox>
          {isAdding ? (
            <SoftBox bgColor="grey-100" borderRadius="md" p={2} pl={3}>
              <form onSubmit={formik.handleSubmit}>
                <SoftBox mb={0.5} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="body2"
                    color="error"
                  >
                    Brand Name*
                  </SoftTypography>
                </SoftBox>
                <SoftBox maxWidth={300}>
                  <SoftInput
                    name="name"
                    type="text"
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    size="small"
                  />
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={2}
                  width="100%"
                  gap={1}
                >
                  <SoftButton type="submit" size="small" color="info">
                    {editBrand ? "Update" : "Save"}
                  </SoftButton>
                  <SoftButton
                    onClick={() => {
                      setIsAdding(false);
                      setEditBrand(null);
                    }}
                    color="secondary"
                    size="small"
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </form>
            </SoftBox>
          ) : (
            <SoftBox>
              <SoftButton
                onClick={() => setIsAdding(true)}
                size="small"
                color="info"
                noElevation
              >
                New Brand
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <Divider sx={{ margin: 0 }} />
      <SoftBox bgColor="grey-100" p={1} pl={3}>
        <SoftTypography variant="h6" color="secondary">
          Brands List
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ margin: 0 }} />
      <SoftBox>
        {brands.map((brand, index) => (
          <>
            <SoftBox
              key={brand.id}
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onMouseEnter={(e) => {
                e.currentTarget.querySelector(".actions").style.display =
                  "flex";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.querySelector(".actions").style.display =
                  "none";
              }}
              pl={3}
              pt={0.5}
              pb={1}
            >
              <SoftTypography variant="body2">{brand?.name}</SoftTypography>
              <SoftBox className="actions" display="none">
                <SoftButton
                  onClick={() => handleEdit(brand)}
                  variant="text"
                  color="info"
                >
                  Edit
                </SoftButton>
                {/* <SoftButton
                onClick={() => handleDelete(brand.id)}
                size="small"
                color="secondary"
                variant="text"
                color="info"
              >
                Delete
              </SoftButton> */}
              </SoftBox>
            </SoftBox>
            {index < brands.length - 1 && <Divider sx={{ margin: 0 }} />}
          </>
        ))}
      </SoftBox>
    </DialogV1>
  );
};

export default ManageBrandsDialog;
