import { FIELD_NAMES } from "../../config/globalConsts";
// Field details
import {
  academicYearFieldDetails,
  zoneFieldDetails,
  batchFieldDetails,
  branchFieldDetails,
  classFieldDetails,
  sectionFieldDetails,
  dateDetails,
  hostelFieldDetails,
  time,
  textField,
  checkBox,
  switchField,
  radioField,
  dropdownField,
  bankFieldDetails,
  bankAccountFieldDetails,
  feeTypeFieldDetails,
  installmentsFieldDetails,
  counsellorFieldDetails,
  accessLevelsField,
  designationField,
  floorFieldDetails,
  tests,
  courseFieldDetails,
  providerFieldDetails,
  employeesField,
  muidateDetails,
  fromdateDetails,
  todateDetails,
} from "./fields";

import {
  phaseDetails,
  admissionType,
  religion,
  nationality,
  bloodGroup,
  motherTounge,
  source,
  gender,
  caste,
  subCaste,
  duration,
  groupBy,
  punch,
  days,
  applicationStatus,
} from "./staticContentFields";

export const dependentFieldNames = {
  [FIELD_NAMES.ZONE]: [
    FIELD_NAMES.BRANCH,
    FIELD_NAMES.CLASS,
    FIELD_NAMES.BATCH,
    FIELD_NAMES.SECTION,
  ],
  [FIELD_NAMES.BRANCH]: [
    FIELD_NAMES.CLASS,
    FIELD_NAMES.BATCH,
    FIELD_NAMES.SECTION,
    FIELD_NAMES.TEST,
    "timings",
  ],
  [FIELD_NAMES.CLASS]: [FIELD_NAMES.BATCH, FIELD_NAMES.SECTION, "timings"],
  [FIELD_NAMES.BATCH]: [FIELD_NAMES.SECTION, FIELD_NAMES.TEST, "timings"],
};

export const formInterDependents = {
  zone: ["branch", "class", "batch", "section"],
  branch: ["class", "batch", "section", "tests"],
  class: ["batch", "section"],
  batch: ["section", "tests"],
};

export const staticAdmissionFieldsMap = {
  [FIELD_NAMES.ADMISSION_TYPE]: admissionType,
  [FIELD_NAMES.APPLICATION_STATUS]: applicationStatus,
  [FIELD_NAMES.MOTHER_TOUNGE]: motherTounge,
  [FIELD_NAMES.BLOOD_GROUP]: bloodGroup,
  [FIELD_NAMES.RELIGION]: religion,
  [FIELD_NAMES.NATIONALITY]: nationality,
  [FIELD_NAMES.SOURCE]: source,
  [FIELD_NAMES.GENDER]: gender,
  [FIELD_NAMES.CASTE]: caste,
  [FIELD_NAMES.PHASE]: phaseDetails,
  [FIELD_NAMES.SUBCASTE]: subCaste,
  [FIELD_NAMES.DURATION]: duration,
  [FIELD_NAMES.GROUPBY]: groupBy,
  [FIELD_NAMES.PUNCH]: punch,
  [FIELD_NAMES.DAY]: days,
};

export default {
  // basic
  [FIELD_NAMES.ACADEMIC_YEAR]: academicYearFieldDetails,
  [FIELD_NAMES.ZONE]: zoneFieldDetails,
  [FIELD_NAMES.BRANCH]: branchFieldDetails,
  [FIELD_NAMES.CLASS]: classFieldDetails,
  [FIELD_NAMES.BATCH]: batchFieldDetails,
  [FIELD_NAMES.SECTION]: sectionFieldDetails,
  [FIELD_NAMES.COURSE]: courseFieldDetails,
  [FIELD_NAMES.PROVIDER]: providerFieldDetails,
  // exam
  [FIELD_NAMES.TEST]: tests,
  // date
  [FIELD_NAMES.DATE]: dateDetails,
  // management
  [FIELD_NAMES.HOSTEL_NAME]: hostelFieldDetails,
  [FIELD_NAMES.BANK_NAMES]: bankFieldDetails,
  [FIELD_NAMES.BANK_ACCOUNT_NO]: bankAccountFieldDetails,
  [FIELD_NAMES.TEXT_FIELD]: textField,
  [FIELD_NAMES.TIME]: time,
  [FIELD_NAMES.CHECKBOX]: checkBox,
  [FIELD_NAMES.SWITCH]: switchField,
  [FIELD_NAMES.RADIO]: radioField,
  [FIELD_NAMES.TO_DATE]: fromdateDetails,
  [FIELD_NAMES.FROM_DATE]: todateDetails,
  [FIELD_NAMES.MUI_DATE]: muidateDetails,
  [FIELD_NAMES.DROPDOWN_FIELD]: dropdownField,
  [FIELD_NAMES.FEE_TYPE]: feeTypeFieldDetails,
  [FIELD_NAMES.INSTALLMENTS]: installmentsFieldDetails,
  [FIELD_NAMES.COUNSELLOR]: counsellorFieldDetails,
  [FIELD_NAMES.ACCESS_LEVEL]: accessLevelsField,
  [FIELD_NAMES.DESIGNATION]: designationField,
  [FIELD_NAMES.FLOOR_NAMES]: floorFieldDetails,
  [FIELD_NAMES.EMPLOYEE]: employeesField,
  ...staticAdmissionFieldsMap,
};
