import { useMemo, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
// selectors
import { selectBranchesMapByBranchCode } from "redux/organization/branches/branches.selectors";
import { selectAcademicYears } from "redux/organization/academicYear/academicYear.selectors";
import { selectAppDialog } from "redux/app/app.selectors";
import { selectFeeTypes } from "redux/finance/makePayment/makePayment.selectors";
// actions
import { fetchFeeTypesStart } from "redux/finance/makePayment/makePayment.slice";
// mui
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
// myComponents
import SoftBox from "myComponents/SoftBox";
import SoftInput from "myComponents/SoftInput";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import SoftTypography from "myComponents/SoftTypography";
import SoftButton from "myComponents/SoftButton";
// components
import DialogV1 from "components/CustomDialog/v1";
import Grid from "@mui/material/Grid";
// apis
import { makeDynamicFeeAdjustMent } from "APIs/finance/feePlan";
// hooks
import useFetch from "hooks/useFetch";

const initialValues = {
  adjustmentConfig: [
    {
      academicYear: "",
      branch: "",
      feeType: "",
      amount: "",
    },
  ],
  remarks: "", // Added remarks field
};

const validationSchema = yup.object({
  adjustmentConfig: yup.array().of(
    yup.object({
      academicYear: yup.string().required("Required"),
      branch: yup.string().required("Required"),
      feeType: yup.string().required("Required"),
      amount: yup.string().required("Required"),
    })
  ),
  remarks: yup
    .string()
    .required("Remarks are required")
    .min(15, "Remarks must be at least 15 characters"), // Added validation for remarks
});

const DynamicFeeAdjustment = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const branchesMap = useSelector(selectBranchesMapByBranchCode);
  const academicYears = useSelector(selectAcademicYears);
  const { dialogData } = useSelector(selectAppDialog);
  const [l0, e0, d0, f0, r0] = useFetch(makeDynamicFeeAdjustMent);
  const { txnId, sid, txnAmount, initialValues: existing } = dialogData;
  const feeTypes = useSelector(selectFeeTypes);

  const ayMap = useMemo(
    () =>
      academicYears.reduce((acc, curr) => {
        acc[curr.code] = curr;
        return acc;
      }, {}),
    [academicYears]
  );

  const feeTypesMap = useMemo(
    () =>
      feeTypes.reduce((acc, curr) => {
        acc[curr.feeType] = curr;
        return acc;
      }, {}),
    [feeTypes]
  );

  useEffect(() => {
    dispatch(fetchFeeTypesStart());
  }, []);

  useEffect(() => {
    if (d0) {
      if (d0.status && d0.status === "SUCCESS") {
        enqueueSnackbar("Fee Adjustment Successful", { variant: "success" });
        handleClose();
      }
      if (d0.status && d0.status === "FAILURE") {
        enqueueSnackbar("Fee Adjustment Failed", { variant: "warning" });
      }
    }

    if (e0) {
      enqueueSnackbar(e0, { variant: "error" });
    }

    return () => {
      r0();
    };
  }, [d0, e0]);

  return (
    <DialogV1
      open={open}
      onClose={handleClose}
      title="Dynamic Fee Adjustment"
      maxWidth="lg"
    >
      <SoftBox p={3}>
        <Formik
          initialValues={
            existing && existing.adjustmentConfig.length > 0
              ? existing
              : initialValues
          }
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => {
            const sum = values.adjustmentConfig.reduce(
              (acc, curr) => acc + parseFloat(curr.amount),
              0
            );
            if (Number(txnAmount) !== sum) {
              enqueueSnackbar("Amounts do not match", { variant: "warning" });
              return;
            }
            f0({
              txnId,
              sid,
              ...values,
              adjustmentConfig: values.adjustmentConfig.map((config) => ({
                ...config,
                amount: parseFloat(config.amount),
              })),
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldArray
                    name="adjustmentConfig"
                    render={(arrayHelpers) => (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {values.adjustmentConfig.map((config, index) => {
                            const { academicYear, branch, feeType, amount } =
                              config;

                            const myAy = ayMap[academicYear] || null;
                            const myBranch = branchesMap[branch] || null;
                            const myFeeType = feeTypesMap[feeType] || null;

                            return (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={6} md={3}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Academic Year
                                  </SoftTypography>
                                  <SoftAutoComplete
                                    list={academicYears}
                                    selected={myAy}
                                    handleSelect={(e, selected) =>
                                      setFieldValue(
                                        `adjustmentConfig.${index}.academicYear`,
                                        selected?.code || ""
                                      )
                                    }
                                    optionLabelPropName="code"
                                    optionValuePropName="code"
                                  />
                                  {touched.adjustmentConfig?.[index]
                                    ?.academicYear &&
                                  errors.adjustmentConfig?.[index]
                                    ?.academicYear ? (
                                    <SoftTypography
                                      variant="caption"
                                      color="error"
                                    >
                                      {
                                        errors.adjustmentConfig[index]
                                          .academicYear
                                      }
                                    </SoftTypography>
                                  ) : null}
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Branch
                                  </SoftTypography>
                                  <SoftAutoComplete
                                    list={Object.values(branchesMap)}
                                    selected={myBranch}
                                    handleSelect={(e, selected) =>
                                      setFieldValue(
                                        `adjustmentConfig.${index}.branch`,
                                        selected?.branchCode || ""
                                      )
                                    }
                                    optionLabelPropName="branchName"
                                    optionValuePropName="branchCode"
                                  />
                                  {touched.adjustmentConfig?.[index]?.branch &&
                                  errors.adjustmentConfig?.[index]?.branch ? (
                                    <SoftTypography
                                      variant="caption"
                                      color="error"
                                    >
                                      {errors.adjustmentConfig[index].branch}
                                    </SoftTypography>
                                  ) : null}
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Fee Type
                                  </SoftTypography>
                                  <SoftAutoComplete
                                    list={feeTypes}
                                    selected={myFeeType || null}
                                    handleSelect={(e, selected) =>
                                      setFieldValue(
                                        `adjustmentConfig.${index}.feeType`,
                                        selected?.feeType || ""
                                      )
                                    }
                                    optionLabelPropName="feeType"
                                    optionValuePropName="feeType"
                                  />
                                  {touched.adjustmentConfig?.[index]?.feeType &&
                                  errors.adjustmentConfig?.[index]?.feeType ? (
                                    <SoftTypography
                                      variant="caption"
                                      color="error"
                                    >
                                      {errors.adjustmentConfig[index].feeType}
                                    </SoftTypography>
                                  ) : null}
                                </Grid>
                                <Grid item xs={6} md={2}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Amount
                                  </SoftTypography>
                                  <SoftInput
                                    name={`adjustmentConfig.${index}.amount`}
                                    type="text"
                                    value={amount}
                                    onChange={handleChange}
                                    label="Amount"
                                    error={
                                      touched.adjustmentConfig?.[index]
                                        ?.amount &&
                                      Boolean(
                                        errors.adjustmentConfig?.[index]?.amount
                                      )
                                    }
                                  />
                                  {touched.adjustmentConfig?.[index]?.amount &&
                                  errors.adjustmentConfig?.[index]?.amount ? (
                                    <SoftTypography
                                      variant="caption"
                                      color="error"
                                    >
                                      {errors.adjustmentConfig[index].amount}
                                    </SoftTypography>
                                  ) : null}
                                </Grid>

                                <Grid item xs={1}>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    height="100%"
                                    width="100%"
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => arrayHelpers.remove(index)}
                                      fleGrow={1}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>

                        <Grid item xs={12}>
                          <SoftButton
                            size="small"
                            color="info"
                            onClick={() =>
                              arrayHelpers.push({
                                academicYear: "",
                                branch: "",
                                feeType: "",
                                amount: "",
                              })
                            }
                            variant="plain"
                          >
                            Add Adjustment
                          </SoftButton>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Remarks
                  </SoftTypography>
                  <SoftBox maxWidth={500}>
                    <SoftInput
                      name="remarks"
                      type="text"
                      value={values.remarks}
                      onChange={handleChange}
                      label="Remarks"
                      multiline
                      rows={3}
                      error={touched.remarks && Boolean(errors.remarks)}
                    />
                  </SoftBox>
                  {touched.remarks && errors.remarks ? (
                    <SoftTypography variant="caption" color="error">
                      {errors.remarks}
                    </SoftTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <SoftBox display="flex" justifyContent="flex-end">
                    <SoftButton
                      type="submit"
                      size="small"
                      color="dark"
                      variant="contained"
                      disabled={l0}
                    >
                      Submit
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </SoftBox>
    </DialogV1>
  );
};

export default DynamicFeeAdjustment;
